// src/UnderConstruction.js
import React from "react";
import "./UnderConstruction.css";

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <h1>🚧 Under Construction 🚧</h1>
      <p>We're working hard to get this page ready. Stay tuned!</p>
    </div>
  );
};

export default UnderConstruction;
